<template>
  <div>
    <Toast :style="{ width: $isMobile() ? '85vw' : '' }" />
    <Dialog
      :visible.sync="show"
      :style="{ width: $isMobile() ? '100vw' : '30vw' }"
      :header="'Alterar Categoria da Lead'"
      :modal="true"
      :closable="false"
    >
      <form
        name="LeadChangeCategory"
        class="p-col-12"
        @submit.prevent="setCategory"
        style="min-height: 15vh"
      >
        <p>
          <b>Lead {{ proposalId }} | {{ proposalSubject }}</b>
        </p>
          <div class="p-field p-col-12 p-md-12">
            <span class="p-float-label">
              <label for="leadCategory">Selecionar Categorias da Lead</label>
            </span>
          </div>
          <div class="p-field p-col-12 p-md-12 p-grid p-ml-1">
            <div
              v-for="solution of categoriesList"
              :key="solution.id"
              class="p-col-2 p-md-6 p-mt-1"
            >
              <Checkbox
                :id="solution.id"
                name="solution"
                :value="solution.id"
                v-model="current"
              />
              <label :for="solution.id" class="p-ml-2">{{
                solution.name
              }}</label>
            </div>
            <small
              v-if="errors.has('leadCategory')"
              class="p-error"
              role="alert"
            >
              Categoria é obrigatório
            </small>
          </div>
      </form>
      <template #footer>
        <Button
          :label="'Guardar'"
          icon="pi pi-check"
          class="p-button-success"
          @click="setCategory"
        />
        <Button
          label="Cancelar"
          icon="pi pi-times"
          class="p-button-danger"
          @click="cancel"
        />
      </template>
    </Dialog>
  </div>
</template>
<script>
import crmService from "../../services/crm.service";
import crmCategoriesService from "../../services/crmCategories.service";
export default {
  name: "ChangeCategory",
  props: ["showToast", "show", "categoryId", "proposalId", "proposalSubject"],
  watch: {
    categoryId() {
      this.$validator.pause();
      this.$validator.reset();
      
      this.current = this.categoryId == null
        ? []
        : this.categoryId.split(";").map(Number);
    },
  },
  data() {
    return {
      current: this.categoryId == null
        ? []
        : this.categoryId.split(";").map(Number),
      categoriesList: [],
    };
  },
  created() {
    this.getCategories();
  },
  methods: {
    getCategories() {
      return crmCategoriesService.getCategories()
        .then((response) => (this.categoriesList = response));
    },
    cancel() {
      return this.$emit("cancel");
    },
    setCategory() {
      this.$validator.validateAll().then((isValid) => {
        if (!isValid) {
          this.loading = false;
          return;
        }
        this.$validator.pause();
        this.$validator.reset();
        let categories = this.current.join(";");
        return crmService
          .setCategory(this.proposalId, { category: categories })
          .then((response) => {
            if (!response) {
              if (this.showToast) {
                this.$toast.add({
                  severity: "error",
                  summary: "Erro ao alterar a categoria da lead",
                  detail: "",
                  life: 3000,
                });
              }
              return this.$emit("changedLeadCategory", {
                status: "error",
                currenTechnican: null,
              });
            }

            if (this.showToast) {
              this.$toast.add({
                severity: "success",
                summary: `Categoria da lead alterada com sucesso`,
                detail: ``,
                life: 3000,
              });
            }
            return this.$emit("changedLeadCategory", {
              status: "ok",
              result: response,
            });
          });
      });
    },
  },
};
</script>
